import NiceModal from '@ebay/nice-modal-react';
import { IntercomProvider } from 'react-use-intercom';
import { AlertProvider } from '@/contexts/alert-context';
import { ContactFreshProvider } from '@/contexts/contact-fresh-context';
import { CookieBannerProvider } from '@/contexts/cookie-banner-context';

const ContextProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <NiceModal.Provider>
      <AlertProvider>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''}
          autoBoot={!!process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
        >
          <ContactFreshProvider>
            <CookieBannerProvider>{children}</CookieBannerProvider>
          </ContactFreshProvider>
        </IntercomProvider>
      </AlertProvider>
    </NiceModal.Provider>
  );
};

export default ContextProvider;
