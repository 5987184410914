import { ComponentType, MouseEventHandler } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import checkboxStyles from '@/components/ui/checkbox.module.css';
import Typography from '@/components/ui/typography';

export type CheckboxChecked = 'yes' | 'no' | 'indeterminate';

export type CheckboxLabel = {
  description?: string;
  title: string;
};

export interface CheckboxProps {
  checked?: CheckboxChecked;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: CheckboxLabel;
  labelColorClass?: string;
  labelElement?: JSX.Element;
  onClick?: MouseEventHandler<HTMLElement>;
  size?: 'sm' | 'md';
  testId?: string;
}

export const Checkbox: ComponentType<CheckboxProps> = ({
  checked,
  disabled,
  fullWidth = false,
  label,
  labelColorClass = 'text-gray-800',
  labelElement,
  onClick,
  size = 'sm',
  testId,
}) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-2',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        fullWidth ? 'w-full' : ''
      )}
      onClick={(e) => {
        if (onClick && !disabled) {
          onClick(e);
        }
      }}
    >
      <button
        className={clsx(
          checkboxStyles['checkbox'],
          checked === 'yes' && checkboxStyles['checkbox--checked'],
          !!label && 'mt-0.5',
          size === 'sm' && 'h-4 w-4 rounded',
          size === 'md' && 'h-5 w-5 rounded-md',
          'flex items-center justify-center'
        )}
        data-test={testId}
        disabled={disabled}
        type="button"
      >
        {checked === 'yes' && (
          <CheckIcon
            className={clsx(
              size === 'sm' && 'h-3 w-3 stroke-[3px]',
              size === 'md' && 'h-3.5 w-3.5 stroke-[3px]'
            )}
          />
        )}
        {checked === 'indeterminate' && (
          <MinusIcon
            className={clsx(
              size === 'sm' && 'h-3 w-3',
              size === 'md' && 'h-3.5 w-3.5'
            )}
          />
        )}
      </button>
      {label ? (
        <div className="min-w-0 flex-1">
          {label.title && (
            <Typography
              className={labelColorClass}
              variant={size === 'sm' ? 'text-button-sm' : 'text-button-md'}
            >
              {label.title}
            </Typography>
          )}
          {label.description ? (
            <Typography
              className="text-gray-600"
              variant={size === 'sm' ? 'text-body-sm' : 'text-body-md'}
            >
              {label.description}
            </Typography>
          ) : null}
        </div>
      ) : labelElement ? (
        labelElement
      ) : null}
    </div>
  );
};

export default Checkbox;
