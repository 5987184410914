import React from 'react';

export const SidebarCloseIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5L4.16667 2.5C3.24619 2.5 2.5 3.24619 2.5 4.16667L2.5 15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5Z"
        stroke="#384250"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M12.5 17.5L12.5 2.5"
        stroke="#384250"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <rect fill="#384250" height="14" transform="translate(13 3)" width="4" />
    </svg>
  );
};
