import React, { ReactElement, SVGProps, useEffect, useState } from 'react';
import { Typography } from '@ds';
import clsx from 'clsx';

type Range<F extends number, T extends number> =
  | Exclude<Enumerate<T>, Enumerate<F>>
  | T;
type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type ValidSize = Range<2, 13>;

type TextToggleItem = {
  MainIcon?:
    | ((props: SVGProps<SVGSVGElement>) => ReactElement | null)
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined;
          titleId?: string | undefined;
        } & React.RefAttributes<SVGSVGElement>
      >;
  label?: string;
  onClick: () => void;
};

interface TextToggleProps {
  equalPartitions?: boolean;
  items: TextToggleItem[];
  size?: ValidSize;
}

const validateSize = (size: number): size is ValidSize => {
  return size >= 2 && size <= 13;
};

export const TextToggle: React.FC<TextToggleProps> = ({
  equalPartitions = false,
  items,
  size = 2,
}) => {
  if (!validateSize(size)) {
    throw new Error('Size must be between 2 and 13');
  }

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [itemWidths, setItemWidths] = useState<number[]>([]);
  const itemRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (items.length > 0 && selectedIndex === null) {
      setSelectedIndex(0);
    }
  }, [items, selectedIndex]);

  // measure item widths after render
  useEffect(() => {
    const widths = itemRefs.current.map((ref) => ref?.offsetWidth || 0);
    setItemWidths(widths);
  }, [items]);

  const handleItemClick = (onClick: () => void, index: number) => {
    onClick();
    setSelectedIndex(index);
  };

  const gridColsSize = `grid-cols-${size}`;

  // calculate the left offset for the slider
  const getSliderOffset = () => {
    const index = selectedIndex || 0;
    return itemRefs.current
      .slice(0, index)
      .reduce((acc, ref) => acc + (ref?.offsetWidth || 0), 0);
  };

  return (
    <div
      className={clsx(
        'relative w-fit overflow-hidden rounded-lg border border-gray-200 bg-gray-100',
        equalPartitions ? `grid ${gridColsSize}` : 'flex'
      )}
    >
      {/* "the slider" */}
      <span
        className="absolute left-0 top-0 mt-[-0.8px] h-[105%] rounded-lg border border-gray-200 bg-white transition-all duration-300"
        style={{
          transform: `translateX(${getSliderOffset()}px)`,
          width:
            selectedIndex !== null ? `${itemWidths[selectedIndex]}px` : '0',
        }}
      />

      {items.map((item, index) => (
        <div
          key={item.label}
          ref={(el) => {
            itemRefs.current[index] = el;
          }}
          className={clsx(
            'relative z-10 cursor-pointer select-none px-4 py-2',
            selectedIndex === index ? 'text-gray-900' : 'text-gray-500'
          )}
          onClick={() => handleItemClick(item.onClick, index)}
        >
          {item.MainIcon ? (
            <item.MainIcon className="h-4 w-4" />
          ) : (
            <Typography variant="text-body-sm">{item.label}</Typography>
          )}
        </div>
      ))}
    </div>
  );
};
