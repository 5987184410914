import React from 'react';

export const DowntrendSparklineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="44"
      viewBox="0 0 48 44"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.3424 40.9037L44.3505 38.9454C44.0099 38.2729 43.3202 37.8491 42.5663 37.8491H42.3482C41.5137 37.8491 40.767 37.3309 40.4748 36.5493L39.0134 32.6394C38.7212 31.8577 37.9744 31.3396 37.14 31.3396H35.2126C34.108 31.3396 33.2126 30.4442 33.2126 29.3396V26.6415C33.2126 25.5369 32.3172 24.6415 31.2126 24.6415H30.561C29.5029 24.6415 28.6278 25.4657 28.5646 26.522L27.7287 40.4813C27.6777 41.3344 26.971 42 26.1164 42C25.2795 42 24.5812 41.3608 24.5075 40.5272L21.8881 10.9308C21.7999 9.93409 20.965 9.16981 19.9644 9.16981C18.9906 9.16981 18.1691 9.89486 18.0481 10.8612L16.5421 22.89C16.4168 23.8906 15.5661 24.6415 14.5576 24.6415H13.8631C12.7941 24.6415 11.9142 23.801 11.8652 22.7332L11.001 3.90829C10.952 2.8405 10.072 2 9.00309 2H2C0.895432 2 0 2.89543 0 4V40C0 41.1046 0.895432 42 2 42H26.1164H47.1266C46.3728 42 45.683 41.5761 45.3424 40.9037Z"
        fill="url(#paint0_linear_608_16168)"
      />
      <path
        d="M48 42H47.1266C46.3728 42 45.683 41.5761 45.3424 40.9037L44.3505 38.9454C44.0099 38.2729 43.3202 37.8491 42.5663 37.8491H42.3482C41.5137 37.8491 40.767 37.3309 40.4748 36.5493L39.0134 32.6394C38.7212 31.8577 37.9744 31.3396 37.14 31.3396H35.2126C34.108 31.3396 33.2126 30.4442 33.2126 29.3396V26.6415C33.2126 25.5369 32.3172 24.6415 31.2126 24.6415H30.561C29.5029 24.6415 28.6278 25.4657 28.5646 26.522L27.7287 40.4813C27.6777 41.3344 26.971 42 26.1164 42V42C25.2795 42 24.5812 41.3608 24.5074 40.5272L21.8881 10.9308C21.7999 9.93409 20.965 9.16981 19.9644 9.16981V9.16981C18.9906 9.16981 18.1691 9.89486 18.0481 10.8612L16.5421 22.89C16.4168 23.8906 15.5661 24.6415 14.5576 24.6415H13.8631C12.7941 24.6415 11.9142 23.801 11.8652 22.7332L11.001 3.90829C10.952 2.8405 10.072 2 9.00309 2H1.07288e-06"
        stroke="#D92D20"
        stroke-width="3"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_608_16168"
          x1="24"
          x2="-1.80263"
          y1="2"
          y2="27.0726"
        >
          <stop stop-color="#EE5C5C" />
          <stop offset="1" stop-color="#D2FFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
