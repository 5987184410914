interface ProgressBarProps {
  max: number;
  percentage?: number;
  value: number;
  width?: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  max,
  percentage,
  value,
  width,
}) => {
  const currentPercentage = percentage ?? (value / max) * 100;

  return (
    <div
      className="relative h-2 w-full overflow-hidden rounded-full bg-green-50"
      style={{ width }}
    >
      <div
        className="absolute left-0 top-0 h-full bg-green-500"
        style={{ width: `${currentPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;
