import { PdfIcon, PhotoIcon } from '@ds';
import { VideoCameraIcon, LinkIcon } from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react-v2/24/outline';
import { IncludedMediaUpdateType, MediaUpdateType } from '@/apollo/generated';

export const MediaUpdateIcon: React.FC<{
  className?: string;
  mediaUpdateType: MediaUpdateType;
}> = ({ className = 'w-5 h-5', mediaUpdateType }) => {
  switch (mediaUpdateType) {
    case MediaUpdateType.Video:
      return <VideoCameraIcon className={className} />;
    case MediaUpdateType.Image:
      return <PhotoIcon className={className} />;
    case MediaUpdateType.Url:
      return <LinkIcon className={className} />;
    case MediaUpdateType.Pdf:
      return <PdfIcon className={className} />;
    case MediaUpdateType.None:
      return <PencilIcon className={className} />;
  }
};

export const IncludedMediaUpdateIcon: React.FC<{
  className?: string;
  includedMediaUpdateType: IncludedMediaUpdateType;
}> = ({ className = 'w-5 h-5', includedMediaUpdateType }) => {
  switch (includedMediaUpdateType) {
    case IncludedMediaUpdateType.Video:
      return <VideoCameraIcon className={className} />;
    case IncludedMediaUpdateType.Image:
      return <PhotoIcon className={className} />;
    case IncludedMediaUpdateType.Url:
      return <LinkIcon className={className} />;
    case IncludedMediaUpdateType.Pdf:
      return <PdfIcon className={className} />;
    case IncludedMediaUpdateType.Multi:
      return (
        <div className="flex items-center gap-1">
          <VideoCameraIcon className={className} />
          <LinkIcon className={className} />
          <PdfIcon className={className} />
          <PhotoIcon className={className} />
        </div>
      );
    default:
      return null;
  }
};
