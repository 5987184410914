// DS V2
import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  ReactElement,
  SVGProps,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Typography } from '@ds';
import { DuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { copyToClipboard } from '@/utils/common-helpers';

export interface TextInputProps {
  LeadingIcon?:
    | ((props: SVGProps<SVGSVGElement>) => ReactElement | null)
    | React.ForwardRefExoticComponent<
        Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
          title?: string | undefined;
          titleId?: string | undefined;
        } & React.RefAttributes<SVGSVGElement>
      >;
  autoFocus?: boolean;
  className?: string;
  containerClassName?: string;
  copyable?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  id?: string;
  label?: string;
  max?: string | number | undefined;
  min?: string | number | undefined;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: 'sm' | 'md';
  style?: React.CSSProperties;
  testId?: string;
  type?: HTMLInputTypeAttribute;
  value?: string | number;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      LeadingIcon,
      autoFocus,
      className,
      containerClassName,
      copyable,
      disabled,
      error,
      helperText,
      id,
      label,
      max,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      placeholder,
      readOnly,
      required = false,
      size = 'md',
      style,
      testId,
      type = 'text',
      value,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current
    );

    const [isCopied, setCopied] = useState(false);

    const onCopy = useCallback(async () => {
      try {
        await copyToClipboard(innerRef.current?.value ?? '');

        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } catch (error) {
        setCopied(false);
      }
    }, []);

    return (
      <div className={clsx('space-y-1.5', containerClassName)}>
        {!!label && (
          <label className="block" htmlFor={id}>
            <Typography
              className="text-gray-700"
              component="span"
              variant="text-label-sm"
            >
              {label}
              {required ? ' *' : null}
            </Typography>
          </label>
        )}
        <div className={clsx('flex', copyable && 'shadow-xs rounded-lg')}>
          <div className="relative flex min-w-0 flex-1 flex-row items-center gap-1">
            {!!LeadingIcon && (
              <LeadingIcon
                className="pointer-events-none absolute left-3.5 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-500"
                strokeWidth={1.5}
              />
            )}
            <input
              ref={innerRef}
              autoFocus={autoFocus}
              className={clsx(
                'box-border w-full font-sans text-base font-normal leading-6 tracking-normal text-gray-900 placeholder:text-gray-500 focus:ring-1 focus:ring-inset disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:placeholder:text-gray-400',
                LeadingIcon ? 'pl-[42px]' : 'pl-3.5',
                copyable ? 'rounded-l-lg' : 'shadow-xs rounded-lg border',
                error
                  ? 'border-red-300 pr-[38px] focus:border-red-300 focus:ring-red-100'
                  : 'focus:border-amplify-green-500 focus:ring-fresh-neon-100 border-gray-300 pr-3.5 disabled:border-gray-200',
                copyable && !error && 'border-r-0 focus:border-r',
                size === 'md' ? 'py-[9px]' : '',
                size === 'sm' ? 'py-[5px]' : '',
                className
              )}
              data-test={testId}
              disabled={disabled}
              id={id}
              maxLength={max ? parseInt(max as string) : undefined}
              min={min}
              name={name}
              placeholder={placeholder}
              readOnly={readOnly}
              required={required}
              style={style}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onKeyPress={onKeyPress}
            />
            {error && (
              <ExclamationCircleIcon className="pointer-events-none absolute right-3.5 top-1/2 h-4 w-4 -translate-y-1/2 text-red-500" />
            )}
          </div>
          {copyable && (
            <div
              className={
                'relative rounded-r-lg border-y border-r border-gray-300'
              }
            >
              <Button
                LeadingIcon={isCopied ? undefined : DuplicateIcon}
                className="h-10 w-24"
                variant="link-gray"
                onClick={onCopy}
              >
                {isCopied ? 'Copied!' : 'Copy'}
              </Button>
            </div>
          )}
        </div>
        {!!helperText && (
          <Typography
            className={error ? 'text-red-500' : 'text-gray-600'}
            variant="text-body-sm"
          >
            {helperText}
          </Typography>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
