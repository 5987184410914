import React from 'react';
import { CheckIcon } from '@heroicons/react-v2/24/outline';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

interface StepperProps {
  setCurrentGuideStep: React.Dispatch<React.SetStateAction<number>>;
  steps: {
    isComplete: boolean;
    isCurrentStep: boolean;
    label: string;
    num: number;
  }[];
}

export const Stepper: React.ComponentType<StepperProps> = ({
  setCurrentGuideStep,
  steps,
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      {steps.map((step, index) => {
        return (
          <div
            key={`stepper-${index}`}
            className={clsx(
              'text-amplify-green-700 flex w-full items-center',
              index + 1 === steps.length
                ? ''
                : step.isComplete
                ? "after:border-amplify-green-700 after:inline-block after:h-0.5 after:w-full after:border-2 after:border-b after:content-['']"
                : "after:inline-block after:h-0.5 after:w-full after:border-2 after:border-b after:border-gray-200 after:content-['']"
            )}
            role="button"
            onClick={(e) => {
              e.preventDefault();
              setCurrentGuideStep(step.num);
            }}
          >
            <div
              className={clsx(
                'flex h-10 w-10 items-center justify-center rounded-full border-2',
                step.isCurrentStep
                  ? 'border-amplify-green-700 bg-amplify-green-700'
                  : step.isComplete
                  ? 'border-amplify-green-700'
                  : 'border-gray-200'
              )}
            >
              {' '}
              <div className="p-5">
                {step.isComplete && !step.isCurrentStep ? (
                  <CheckIcon className="text-amplify-green-700 h-5 w-5" />
                ) : (
                  <Typography
                    className={
                      step.isCurrentStep ? 'text-white' : 'text-gray-500'
                    }
                    variant="text-label-md"
                  >
                    {step.label}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
