import { ComponentType, Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react-v2/24/solid';
import clsx from 'clsx';
import { useOnClickOutside } from 'usehooks-ts';
export interface ModalRightProps {
  children?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
}

export const ModalRight: ComponentType<ModalRightProps> = ({
  children,
  onClose,
  open,
  title,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    onClose();
  });
  return (
    <Transition appear as={Fragment} show={open}>
      <Dialog
        as="div"
        className={clsx('z-modal relative', !open && 'pointer-events-none')}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div
          ref={ref}
          className="fixed inset-y-0 right-0 z-10 flex w-screen justify-center md:w-[480px]"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div className="w-full bg-white shadow-xl">
              <div className="p-6">
                <div className="sticky top-0 z-50 mb-2 flex flex-row justify-between bg-white">
                  <div className="text-xl font-semibold">{title}</div>
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer"
                    onClick={onClose}
                  />
                </div>
                <div
                  className="overflow-y-auto"
                  style={{ maxHeight: 'calc(100vh - 150px)' }}
                >
                  {children}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalRight;
