import { ComponentType, MouseEventHandler } from 'react';
import { Button } from '@ds';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Typography from '@/components/ui/typography';

export interface ToastProps {
  actionLabel?: string;
  controller?: {
    reject: (args?: unknown) => void;
    resolve: (args?: unknown) => void;
  };
  description: string;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  variant: 'success' | 'error';
}

export const Toast: ComponentType<ToastProps> = ({
  actionLabel,
  controller,
  description,
  onDismiss,
  title,
  variant,
}) => {
  const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onDismiss) {
      onDismiss(e);
    }

    if (controller?.reject) {
      controller.reject();
    }
  };

  return (
    <div
      className={clsx(
        'relative flex items-start gap-3 rounded-md py-4 pl-4 shadow-md',
        onDismiss || controller ? 'pr-12' : 'pr-4',
        variant === 'error' && 'bg-red-25',
        variant === 'success' && 'bg-green-25'
      )}
    >
      {variant === 'error' && (
        <ExclamationCircleIcon className="h-5 w-5 text-red-600" />
      )}
      {variant === 'success' && (
        <CheckCircleIcon className="h-5 w-5 text-green-600" />
      )}

      <div className="flex flex-col gap-0.5">
        {title && (
          <Typography
            className={clsx(
              variant === 'error' && 'text-red-700',
              variant === 'success' && 'text-green-800'
            )}
            variant="text-label-sm"
          >
            {title}
          </Typography>
        )}
        <Typography
          className={clsx(
            variant === 'error' && 'text-red-700',
            variant === 'success' && 'text-green-800'
          )}
          variant={title ? 'text-body-sm' : 'text-label-sm'}
        >
          {description}
        </Typography>
        {actionLabel && controller && (
          <Button
            className="underline"
            size="sm"
            variant="link-color"
            onClick={() => {
              if (controller?.resolve) {
                controller.resolve();
              }
            }}
          >
            {actionLabel}
          </Button>
        )}
      </div>
      {(onDismiss || controller) && (
        <button
          className="absolute right-4 top-4"
          type="button"
          onClick={handleDismiss}
        >
          <XIcon
            className={clsx(
              'h-4 w-4',
              variant === 'error' && 'text-red-700',
              variant === 'success' && 'text-amplify-green-700'
            )}
          />
        </button>
      )}
    </div>
  );
};

export default Toast;
