import React from 'react';

export const UptrendSparklineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="44"
      viewBox="0 0 48 44"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.65763 40.9037L3.64945 38.9454C3.99005 38.2729 4.67984 37.8491 5.43366 37.8491H5.65178C6.48625 37.8491 7.23303 37.3309 7.52519 36.5493L8.98662 32.6394C9.27878 31.8577 10.0256 31.3396 10.86 31.3396H12.7874C13.892 31.3396 14.7874 30.4442 14.7874 29.3396V26.6415C14.7874 25.5369 15.6828 24.6415 16.7874 24.6415H17.439C18.4971 24.6415 19.3722 25.4657 19.4354 26.522L20.2713 40.4813C20.3223 41.3344 21.029 42 21.8836 42C22.7205 42 23.4188 41.3608 23.4925 40.5272L26.1119 10.9308C26.2001 9.93409 27.035 9.16981 28.0356 9.16981C29.0094 9.16981 29.8309 9.89486 29.9519 10.8612L31.4579 22.89C31.5832 23.8906 32.4339 24.6415 33.4424 24.6415H34.1369C35.2059 24.6415 36.0858 23.801 36.1348 22.7332L36.999 3.90829C37.048 2.8405 37.928 2 38.9969 2H46C47.1046 2 48 2.89543 48 4V40C48 41.1046 47.1046 42 46 42H21.8836H0.873427C1.62725 42 2.31703 41.5761 2.65763 40.9037Z"
        fill="url(#paint0_linear_608_16149)"
      />
      <path
        d="M0 42H0.873427C1.62725 42 2.31703 41.5761 2.65763 40.9037L3.64945 38.9454C3.99005 38.2729 4.67984 37.8491 5.43366 37.8491H5.65178C6.48625 37.8491 7.23303 37.3309 7.52519 36.5493L8.98662 32.6394C9.27878 31.8577 10.0256 31.3396 10.86 31.3396H12.7874C13.892 31.3396 14.7874 30.4442 14.7874 29.3396V26.6415C14.7874 25.5369 15.6828 24.6415 16.7874 24.6415H17.439C18.4971 24.6415 19.3722 25.4657 19.4354 26.522L20.2713 40.4813C20.3223 41.3344 21.029 42 21.8836 42V42C22.7205 42 23.4188 41.3608 23.4926 40.5272L26.1119 10.9308C26.2001 9.93409 27.035 9.16981 28.0356 9.16981V9.16981C29.0094 9.16981 29.8309 9.89486 29.9519 10.8612L31.4579 22.89C31.5832 23.8906 32.4339 24.6415 33.4424 24.6415H34.1369C35.2059 24.6415 36.0858 23.801 36.1348 22.7332L36.999 3.90829C37.048 2.8405 37.928 2 38.9969 2H48"
        stroke="#039855"
        stroke-width="3"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_608_16149"
          x1="24"
          x2="49.8026"
          y1="2"
          y2="27.0726"
        >
          <stop stop-color="#3CCB8A" />
          <stop offset="1" stop-color="#D2FFEB" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
