// DS V2
import { useMemo } from 'react';
import {
  ApolloClient,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import * as Sentry from '@sentry/nextjs';
import { createLink } from 'apollo-absinthe-upload-link';
import { SentryLink } from 'apollo-link-sentry';
import cookieParser from 'cookie';
import fetch from 'cross-fetch';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function cleanCookie(cookie?: string) {
  if (cookie) {
    return Object.entries(cookieParser.parse(cookie))
      .filter(([key, _value]) => key.startsWith('_athena'))
      .map(([key, value]) => `${key}=${value}`)
      .join('; ');
  }

  return '';
}

function getMarketListingKey(cookie: string): string | null {
  const marketListingKey = window.location.pathname.split('/')[1];

  if (typeof marketListingKey === 'string') {
    return marketListingKey;
  }

  return null;
}

function createApolloLink(cookie?: string, marketLK?: string) {
  const cleanedCookie = cleanCookie(cookie);
  const httpLink = createLink({
    credentials: 'include',
    fetch: (uri, options) => {
      const marketListingKey = marketLK || getMarketListingKey(cleanedCookie);

      if (marketListingKey) {
        return fetch(`${uri}?market_listing_key=${marketListingKey}`, options);
      }

      return fetch(uri, options);
    },
    headers: {
      cookie: cleanedCookie,
    },
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URI,
  });

  if (process.env.NEXT_PUBLIC_SOCKET_URI && typeof document !== 'undefined') {
    const cleanedCookie = cleanCookie(document.cookie);
    let params = cookieParser.parse(cleanedCookie);

    const marketListingKey = getMarketListingKey(cleanedCookie);

    if (marketListingKey) {
      params = {
        ...params,
        marketListingKey,
      };
    }

    const socketLink = new GraphQLWsLink(
      createClient({
        connectionParams: params,
        url: process.env.NEXT_PUBLIC_SOCKET_URI,
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      socketLink,
      httpLink
    );

    return splitLink;
  }

  return httpLink;
}

function createApolloClient(cookie?: string, marketListingKey?: string) {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { locations, message, path } = error;

        Sentry.withScope((scope) => {
          const { operationName, query, variables } = operation;
          // Annotate whether failing operation was query/mutation/subscription
          scope.setTag('kind', operationName);
          // Log query and variables as extras
          // (make sure to strip out sensitive data!)
          scope.setExtra('query', JSON.stringify(query));
          scope.setExtra('variables', JSON.stringify(variables));
          if (path) {
            // We can also add the path as breadcrumb
            scope.addBreadcrumb({
              category: 'query-path',
              level: 'debug',
              message: path.join(' > '),
            });
          }
          Sentry.captureMessage(
            `[GraphQL error]: ${message}, Path: ${(path || []).join(
              ' > '
            )}, Location: ${JSON.stringify(locations)}`,
            'error'
          );
        });
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }
  });

  const link = createApolloLink(cookie, marketListingKey);

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeError: true,
      includeQuery: true,
      includeVariables: true,
    },
    setFingerprint: false,
    setTransaction: false,
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Page: {
          fields: {
            blocks: {
              merge(existing = [], incoming = []) {
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            amplifyInvestors: relayStylePagination(['searchPhrase']),
            announcementsList: relayStylePagination(['options']),
            contacts: relayStylePagination(['options']),
            dynamicLists: relayStylePagination(['options']),
            insightsCursor: relayStylePagination(['options']),
            mediaAnnouncements: relayStylePagination(['options']),
            mediaComments: relayStylePagination(['options']),
            mediaQuestionsList: relayStylePagination(['options']),
            mediaUpdates: relayStylePagination(['options']),
            shareholdings: relayStylePagination(['options']),
            staticLists: relayStylePagination(['options']),
            webinars: relayStylePagination(['options']),
          },
        },
      },
    }),
    link: from([sentryLink, errorLink, link]),
    ssrMode: typeof window === 'undefined',
  });
}

interface Options {
  cookie?: string;
  initialState?: NormalizedCacheObject;
}

export function initApollo(opts?: Options & { marketListingKey?: string }) {
  const _apolloClient =
    apolloClient ?? createApolloClient(opts?.cookie, opts?.marketListingKey);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (opts?.initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(opts.initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState?: NormalizedCacheObject) {
  const store = useMemo(() => initApollo({ initialState }), [initialState]);

  return store;
}
