// DS V2
import { createContext, useContext, useRef, useState } from 'react';
import { Toast, ToastProps } from '@ds';
import clsx from 'clsx';
import { formatError } from '@/utils/error-helpers';

interface AlertContext {
  closeAlert(): void;
  formatAndShowError(error: unknown): void;
  showAlert(args: ToastProps): void;
  showGenericError(): void;
  showMultiAlert(args: ToastProps): Promise<unknown>;
}

interface ToastWithId extends ToastProps {
  id: string;
}

const AlertContext = createContext<AlertContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeAlert() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  formatAndShowError() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showAlert() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showGenericError() {},

  showMultiAlert(): Promise<unknown> {
    return Promise.resolve();
  },
});

export const AlertProvider: React.ComponentType<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [args, setArgs] = useState<ToastProps>({
    description: '',
    variant: 'success',
  });
  const [isVisible, setVisible] = useState(false);
  const [toasts, setToasts] = useState<ToastWithId[]>([]);

  const timeoutRef = useRef<NodeJS.Timeout>();

  function closeAlert() {
    setVisible(false);
  }

  function formatAndShowError(error: unknown) {
    showAlert({
      description: formatError(error),
      variant: 'error',
    });
  }

  async function showAlert(_args: ToastProps) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isVisible) {
      setVisible(false);

      await new Promise((resolve) => setTimeout(resolve, 1500));
    }

    setArgs(_args);
    setVisible(true);

    timeoutRef.current = setTimeout(() => {
      setVisible(false);
    }, 5000);
  }

  function showMultiAlert(args: ToastProps): Promise<unknown> {
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2, 10);

    let originalResolve!: (args?: unknown) => void;
    let originalReject!: (args?: unknown) => void;

    const promise = new Promise((resolve, reject) => {
      originalResolve = resolve;
      originalReject = reject;
    });

    const resolve = (value?: unknown) => {
      setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id)
      );
      originalResolve(value);
    };

    const reject = (reason?: unknown) => {
      setToasts((currentToasts) =>
        currentToasts.filter((toast) => toast.id !== id)
      );
      originalReject(reason);
    };

    const newToast: ToastWithId = {
      ...args,
      controller: {
        reject: reject,
        resolve: resolve,
      },
      id,
    };

    setToasts((currentToasts) => [...currentToasts, newToast]);

    return promise;
  }

  function showGenericError() {
    showAlert({
      description: 'There was an issue with processing your request.',
      variant: 'error',
    });
  }

  return (
    <AlertContext.Provider
      value={{
        closeAlert,
        formatAndShowError,
        showAlert,
        showGenericError,
        showMultiAlert,
      }}
    >
      {children}
      <div
        className={clsx(
          'fixed inset-x-4 bottom-4 z-[9999] cursor-pointer transition duration-500 sm:mx-auto sm:max-w-[480px]',
          isVisible ? 'translate-y-0' : 'translate-y-96'
        )}
        onClick={closeAlert}
      >
        <Toast {...args} />
      </div>

      <div className="fixed bottom-4 right-4 z-[9999] space-y-2 sm:mx-auto sm:max-w-[480px]">
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className={clsx('transition duration-500', 'translate-y-0')}
          >
            <div className="relative">
              <Toast {...toast} />
            </div>
          </div>
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export function useAlert() {
  return useContext(AlertContext);
}
