import React from 'react';

export const StaticSparklineIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="4"
      viewBox="0 0 48 4"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 2.5H45.8976L43.7953 2.39623H40.9606L38.5276 2.23349H33.2963C33.2501 2.23349 33.2126 2.196 33.2126 2.14976V2.14976C33.2126 2.10352 33.1751 2.06604 33.1289 2.06604H29.0779C28.8134 2.06604 28.5515 2.11852 28.3073 2.22045L28.0076 2.34559C27.7635 2.44752 27.5016 2.5 27.237 2.5H24.9149C24.731 2.5 24.5481 2.47466 24.3712 2.42468L21.9989 1.75456C21.822 1.70459 21.6391 1.67925 21.4552 1.67925H18.4576C18.3261 1.67925 18.1949 1.69221 18.0659 1.71797L16.5167 2.02732C16.3878 2.05307 16.2566 2.06604 16.1251 2.06604H12.462C12.1278 2.06604 11.799 1.9823 11.5055 1.82246L11.3606 1.74358C11.0672 1.58374 10.7383 1.5 10.4041 1.5H1.07288e-06"
        stroke="#4D5761"
        stroke-width="3"
      />
    </svg>
  );
};
