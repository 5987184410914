import React from 'react';
import { Popover } from '@headlessui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { CalendarIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import TextInput from '@/components/ui/text-input';
import { getOffsettedDateObject } from '@/utils/timezone';
type Maybe<T> = T | null;
interface Props {
  date: Maybe<Date>;
  dateFormat?: string;
  dateFormatPopover?: string;
  disableTyping?: boolean;
  disabled?: boolean;
  inputWidth?: number;
  maxDate?: Date;
  minDate?: Date;
  openUpwards?: boolean;
  setDate: (date: Maybe<Date>) => void;
  showIcon?: boolean;
  userTimeZoneOffset?: number;
}

interface DatePickerPopoverContentProps extends Props {
  closePopover: (
    focusableElement?:
      | HTMLElement
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void;
}

const DatePickerPopoverContent: React.FC<DatePickerPopoverContentProps> = ({
  closePopover,
  date,
  dateFormatPopover,
  disableTyping = false,
  inputWidth,
  maxDate,
  minDate,
  openUpwards,
  setDate,
  userTimeZoneOffset,
}) => {
  return (
    <div
      className={clsx(
        'min-w-[320px] rounded-lg bg-white',
        openUpwards ? 'flex flex-col-reverse' : ''
      )}
      style={{ width: inputWidth }}
    >
      <div className="date-range-inputs !z-50" style={{ width: inputWidth }}>
        <ReactDatePicker
          customInput={
            <TextInput
              disabled={disableTyping}
              style={{
                width: inputWidth ? inputWidth - 1.5 : inputWidth,
              }}
            />
          }
          dateFormat={dateFormatPopover || 'dd/MM/yyyy'}
          disabled={disableTyping}
          maxDate={maxDate ? getOffsettedDateObject(maxDate) : null}
          minDate={minDate ? getOffsettedDateObject(minDate) : null}
          placeholderText="dd/mm/yyyy"
          popperClassName="hidden"
          selected={date}
          strictParsing={true}
          onChange={(date) => {
            setDate(date);
            closePopover();
          }}
        />
      </div>
      <ReactDatePicker
        inline
        showMonthDropdown
        showYearDropdown
        customInput={
          <TextInput disabled={disableTyping} style={{ width: inputWidth }} />
        }
        dateFormat="dd/MM/yyyy"
        dateFormatCalendar="MMMM yyyy"
        disabled={disableTyping}
        dropdownMode="select"
        maxDate={maxDate ? getOffsettedDateObject(maxDate) : null}
        minDate={minDate ? getOffsettedDateObject(minDate) : null}
        nextMonthButtonLabel={<ChevronRightIcon />}
        previousMonthButtonLabel={<ChevronLeftIcon />}
        selected={date}
        onChange={(date) => {
          setDate(date);
          closePopover();
        }}
      />
    </div>
  );
};

export const DatePicker: React.FC<Props> = ({
  date,
  dateFormat,
  dateFormatPopover,
  disableTyping = false,
  disabled = false,
  maxDate,
  minDate,
  openUpwards,
  setDate,
  showIcon = false,
  userTimeZoneOffset,
}) => {
  const buttonRef = React.useRef<HTMLDivElement>(null);
  return (
    <Popover className="relative">
      <Popover.Button className="w-full focus:outline-none" disabled={disabled}>
        {({ open }) => (
          <div
            ref={buttonRef}
            className={clsx(
              'date-range-inputs !mb-[0] w-full min-w-max px-3',
              open && 'hidden'
            )}
          >
            <p
              className={clsx(
                'input-v2 px-3.5 py-2 pt-2.5 text-left',
                disabled && 'bg-gray-100'
              )}
            >
              {dayjs(date).format(dateFormat || 'DD/MM/YYYY')}
            </p>
            {showIcon && <CalendarIcon className="h-5 w-5 text-gray-400" />}
          </div>
        )}
      </Popover.Button>

      <Popover.Panel
        className={clsx(
          'absolute z-50 shadow-lg',
          openUpwards ? 'bottom-0' : 'top-0'
        )}
      >
        {({ close }) => (
          <DatePickerPopoverContent
            closePopover={close}
            date={date}
            dateFormatPopover={dateFormatPopover}
            disableTyping={disableTyping}
            inputWidth={buttonRef.current?.offsetWidth}
            maxDate={maxDate}
            minDate={minDate}
            openUpwards={openUpwards}
            setDate={setDate}
            userTimeZoneOffset={userTimeZoneOffset}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DatePicker;
