// DS V2
import React from 'react';
import clsx from 'clsx';
interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const ChartLoading: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'flex h-[360px] w-full items-center justify-center p-6',
        className ? className : ''
      )}
    >
      <div className="h-full w-full animate-pulse rounded-lg bg-gray-50" />
    </div>
  );
};

export default ChartLoading;
